/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Header from '../../Header/Header';
import styles from './Notice.module.scss';
import ArrowLeftIcon from '../../../assets/icons/arrow_left.svg';

import notices from '../../../assets/notices';
import useMobile from '../../../hooks/useMobile';
import { useNavigate, useParams } from 'react-router-dom';

const IR = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { id } = useParams();
  const notice = notices.find((notice) => notice.id === id);
  const isNotice = notice !== undefined;
  const isIR = notice?.type === 'IR';

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.notice_container}>
        <div className={styles.content_wrapper}>
          {isIR && isNotice && (
            <div className={styles.IR_container}>
              <div className={styles.title}>
                {!isMobile && (
                  <img
                    src={ArrowLeftIcon}
                    alt="arrow"
                    onClick={() => {
                      navigate(-1);
                    }}
                    className={styles.title_back_icon}
                  />
                )}
                <p className={styles.title_text}>{notice?.title}</p>
              </div>
              <div className={styles.date}>{notice?.date}</div>
              <div className={styles.content}>
                {notice?.content.split('\n').map((n) => {
                  return <p>{n}</p>;
                })}
              </div>
              <div className={styles.content_footer}>
                {notice?.content_footer?.map((n) => {
                  return <p>{n}</p>;
                })}
              </div>
            </div>
          )}
          {(!isIR || !isNotice) && (
            <div className={styles.not_found}>잘못된 접근입니다.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IR;
