import styled from 'styled-components';
import Main from './components/Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Notice from './components/pages/notice/Notice';
import Contact from './components/pages/contact/Contact';
import IR from './components/pages/notice/IR';

const AppWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: block;
  overflow-x: hidden;
`;

function App() {
  return (
    <AppWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:id" element={<IR />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  );
}

export default App;
