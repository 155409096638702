interface Notice {
  id: string;
  title: string;
  date: string;
  type: 'PR' | 'IR';
  link: string;
  content: string;
  content_footer?: string[];
  attachements: string[];
}

const notices: Notice[] = [
  {
    id: '18',
    title: '디오리진-돌고도네이션, 글로벌 IP 사업 협력 MOU…비즈니스 다각화',
    date: '2024.06.11',
    type: 'PR',
    link: 'https://n.news.naver.com/mnews/article/018/0005761007?sid=105',
    content: '',
    attachements: [],
  },
  {
    id: '17',
    title:
      '디오리진, 오리지널 IP ‘헬그라운드’ 웹툰화 돌입.. "모범택시" 까를로스 작가와 협업',
    date: '2024.05.20',
    type: 'PR',
    link: 'https://www.fnnews.com/news/202405200920528165',
    content: '',
    attachements: [],
  },
  {
    id: '16',
    title:
      '디오리진, 밀리언볼트와 IP 가치 제고 협약... “전략적 협력 통해 글로벌 공략 박차”',
    date: '2024.05.09',
    type: 'PR',
    link: 'http://m.newstap.co.kr/news/articleView.html?idxno=217021',
    content: '',
    attachements: [],
  },
  {
    id: '15',
    title: '디오리진-하이크, 콘텐츠 사업 맞손…"글로벌 IP 비즈니스 확장”',
    date: '2024.04.04',
    type: 'PR',
    link: 'https://www.edaily.co.kr/news/read?newsId=01892566638852840&mediaCodeNo=257&OutLnkChk=Y',
    content: '',
    attachements: [],
  },
  {
    id: '14',
    title:
      'K콘텐츠에 매료된 IP 전통 강자 일본···시장 활기에 국내 기업들 日 공략 분주',
    date: '2024.04.04',
    type: 'PR',
    link: 'https://sports.khan.co.kr/entertainment/sk_index.html?art_id=202404040539013&sec_id=540201&pt=nv',
    content: '',
    attachements: [],
  },
  {
    id: '13',
    title: '주식의 액면분할로 인한 구주권 제출 공고',
    date: '2024.04.02',
    type: 'IR',
    link: '',
    content:
      '당 회사는 2024년 03월 12일 (화) 정기 주주총회 결의로 1주의 액면금액 금5,000원의 주식 1주를 분할하여 1주의 금액 금100원의 주식 50주로 하기로 하였으므로 구주권을 가진 주주님과 질권자께서는 이 공고의 개제일로부터 1개월 이내에 구주권을 회사에 제출 하시기 바랍니다.',
    content_footer: [
      '2024년 04월 02일',
      '주식회사 디오리진',
      '대표이사 정재식',
      '(서울특별시 용산구 유엔빌리지길 80-90, 225호)',
    ],
    attachements: [],
  },
  {
    id: '12',
    title: '디오리진 조민수 디렉터 "IP 공동 창작 시스템으로 비용 효율화"',
    date: '2024.03.15',
    type: 'PR',
    link: 'https://www.news1.kr/articles/5350925',
    content: '',
    attachements: [],
  },
  {
    id: '11',
    title: '디오리진, 게임 IP 확대 위해 라인게임즈 출신 김미은 이사 영입',
    date: '2024.02.22',
    type: 'PR',
    link: 'https://www.thisisgame.com/webzine/game/nboard/225/?n=184972',
    content: '',
    attachements: [],
  },
  {
    id: '10',
    title:
      '디오리진, 콘텐츠 ‘더 킹 오브 파이터즈’ 참여 크리에이터들과 공동 개발 착수',
    date: '2024.01.16',
    type: 'PR',
    link: 'https://m.thisisgame.com/webzine/nboard/225/?n=183406',
    content: '',
    attachements: [],
  },
  {
    id: '9',
    title: '디오리진, 김칸비 작가와 신규 IP 개발 나선다',
    date: '2023.12.01',
    type: 'PR',
    link: 'https://www.edaily.co.kr/news/read?newsId=01443206635834256',
    content: '',
    attachements: [],
  },
  {
    id: '8',
    title: '디오리진, 글로벌 IP 사업 전문가 강지석 이사 영입',
    date: '2023.11.08',
    type: 'PR',
    link: 'https://news.mt.co.kr/mtview.php?no=2023110809230471574',
    content: '',
    attachements: [],
  },
  {
    id: '7',
    title: '초기투자에 133억 뭉칫돈...잘 키운 "이것" 하나, 회사 먹여 살린다',
    date: '2023.10.10',
    type: 'PR',
    link: 'https://n.news.naver.com/mnews/article/008/0004946621?sid=101',
    content: '',
    attachements: [],
  },
  {
    id: '6',
    title: '"K콘텐츠 열풍, 우리도 뜬다"…몸집 불리는 "이곳" 생태계',
    date: '2023.10.03',
    type: 'PR',
    link: 'https://n.news.naver.com/mnews/article/003/0012123814?sid=101',
    content: '',
    attachements: [],
  },
  {
    id: '5',
    title:
      '디오리진, 글로벌 비즈니스 강화 위해 콩스튜디오 출신 최원호 CFO 영입',
    date: '2023.09.18',
    type: 'PR',
    link: 'https://mirakle.mk.co.kr/view.php?year=2023&no=707971',
    content: '',
    attachements: [],
  },
  {
    id: '4',
    title: '황금알을 낳는 "스토리 IP"를 쫓는 스타트업들 … 투자시장도 관심↑',
    date: '2023.09.11',
    type: 'PR',
    link: 'https://www.the-stock.kr/news/articleView.html?idxno=18863',
    content: '',
    attachements: [],
  },
  {
    id: '3',
    title: 'K-콘텐츠 산업 혁신 이끄는 스타트업들',
    date: '2023.08.25',
    type: 'PR',
    link: 'https://platum.kr/archives/212462',
    content: '',
    attachements: [],
  },
  {
    id: '2',
    title:
      '디오리진, 조민수 감독과 멀티 유저블 오리지널 IP "갓트웰브" 프로젝트 전개',
    date: '2023.08.10',
    type: 'PR',
    link: 'https://www.hankyung.com/it/article/2023081000967',
    content: '',
    attachements: [],
  },
  {
    id: '1',
    title: '‘시드 유치’ 디오리진, IP 홀딩스 구현 본격화',
    date: '2023.06.15',
    type: 'PR',
    link: 'https://www.thebell.co.kr/free/content/ArticleView.asp?key=202306131426539880108414&lcode=00',
    content: '',
    attachements: [],
  },
];

export default notices;
