import MemberCard from './MemberCard';
import styles from './Members.module.scss';
import { members, creators } from '../../assets/members';

const Members = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {creators.map((creator, i) => {
          return <MemberCard member={creator} key={i} delay={i * 100} />;
        })}
      </div>
      <div className={styles.container}>
        {members.map((creator, i) => {
          return <MemberCard member={creator} key={i} delay={i * 100} />;
        })}
      </div>
    </div>
  );
};

export default Members;
