const deviceSizes = {
  mobile: 768,
};

const device = {
  mobile: `screen and (max-width: ${deviceSizes.mobile}px)`,
};

export const lightTheme = {
  device,
  appearance: { mode: 'light', textColor: 'black', bgColor: 'white' },
};

export const darkTheme = {
  device,
  appearance: { mode: 'dark', textColor: 'white', bgColor: 'black' },
};

export const theme = {
  lightTheme,
  darkTheme,
};
