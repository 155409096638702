/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import styles from './Header.module.scss';
import logo_white from '../../assets/logo.svg';
import HamburgerIcon from '../../assets/icons/hamburger.svg';
import ExIcon from '../../assets/icons/ex_white.svg';
import { useState } from 'react';

interface HeaderProps {
  dark?: boolean;
}

const Header = ({ dark = true }: HeaderProps) => {
  const [menu, setMenu] = useState(false);
  return (
    <div className={styles.header}>
      <div className={styles.logo_wrapper}>
        <img
          src={logo_white}
          alt="logo"
          className={styles.logo}
          draggable={false}
          onClick={() => {
            window.location.href = '/';
          }}
          style={{
            filter: dark ? 'invert(1)' : 'invert(0)',
          }}
        />
      </div>

      <div className={styles.button_wrapper}>
        <div
          className={`${styles.button_menu} ${
            dark ? styles.dark : styles.light
          }`}
          onClick={() => {
            setMenu(!menu);
          }}
        >
          <img
            src={menu ? ExIcon : HamburgerIcon}
            alt="menu"
            style={{
              filter: dark ? 'invert(1)' : 'invert(0)',
            }}
          />
        </div>
      </div>
      {menu && (
        <div className={styles.button_menu_wrapper}>
          <p
            onClick={() => {
              window.location.href = '/notice';
            }}
            style={{ color: dark ? '#000' : '#fff' }}
          >
            Notice
          </p>
          <p
            onClick={() => {
              window.location.href = '/contact';
            }}
            style={{ color: dark ? '#000' : '#fff' }}
          >
            Contact
          </p>
          <p
            onClick={() => {
              window.open('https://theorigin.team/');
            }}
            style={{ color: dark ? '#000' : '#fff' }}
          >
            Career
          </p>
        </div>
      )}
    </div>
  );
};

export default Header;
