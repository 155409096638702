import AOS from 'aos';
import { useEffect } from 'react';
import { Member } from '../../assets/members';
import styles from './MemberCard.module.scss';

interface MemberCardProps {
  member: Member | null;
  delay?: number;
}

const MemberCard = ({ member, delay = 0 }: MemberCardProps) => {
  useEffect(() => {
    AOS.init();
  }, []);
  if (!member) {
    return (
      <div className={styles.card_container}>
        <div className={styles.profile_blank} />
      </div>
    );
  }
  return (
    <div
      // data-aos="fade-up"
      // data-aos-delay={delay}
      className={styles.card_container}
    >
      <img src={member.image} alt="member" className={styles.profile_image} />
      <div className={styles.name}>{member.en_name}</div>
      {member.en_bio.map((line, i) => {
        return (
          <div className={styles.bio} key={i}>
            {line}
          </div>
        );
      })}
      {/* <div className={styles.bio}>{member.en_bio?.join('\r\n')}</div> */}
    </div>
  );
};

export default MemberCard;
