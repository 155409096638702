import { Modal } from '@mui/material';
import styles from './common.module.scss';
import CloseIcon from '../../assets/icons/ex.svg';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const PrivacyModal = ({ open, handleClose }: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modal_wrapper}>
        <div className={styles.close} onClick={handleClose}>
          <img src={CloseIcon} alt="close" />
        </div>
        <div className={styles.container}>
          <h1>Privacy Policy</h1>
          <div className={styles.paragraph}>
            <strong>
              Purpose of Collection and Use of Personal Information
            </strong>
            <p>Collecting basic information for customer inquiries</p>
          </div>
          <div className={styles.paragraph}>
            <strong>Items Collected</strong>
            <p>
              Mandatory items: Name, Company Name, Email, Contact Information
            </p>
            <p>
              Optional items: Department, Position, Attachments, Inquiry Details
            </p>
          </div>
          <div className={styles.paragraph}>
            <strong>Retention Period</strong>
            <p>
              In principle, personal information collected and used for its
              intended purpose will be destroyed without delay after achieving
              its purpose.
            </p>
            <p>
              However, if the retention period agreed upon by the user has
              expired or the purpose of processing has been achieved, but the
              personal information must still be retained according to other
              related laws, such personal information may be kept.
            </p>
            <p>
              Destruction procedure: Personal information for which a reason for
              destruction has occurred will be selected and destroyed.
            </p>
            <p>
              Method of destruction: Personal information recorded and stored in
              electronic file format will be destroyed in a manner that makes it
              impossible to regenerate the records. Personal information
              recorded and stored on paper will be shredded by a shredder.
            </p>
          </div>
          <div className={styles.paragraph}>
            <strong>
              Right to Refuse Consent and the Disadvantages of Refusing Consent
            </strong>
            <p>
              Consent to the collection and use of essential information is
              considered mandatory for submitting an inquiry, thus it is assumed
              that consent has been given upon submission of the inquiry.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyModal;
