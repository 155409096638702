/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Header from '../../Header/Header';
import styles from './Notice.module.scss';

import FileIcon from '../../../assets/icons/file.svg';
import ArrowRightIcon from '../../../assets/icons/arrow_right.svg';
import ArrowLeftIcon from '../../../assets/icons/arrow_left.svg';

import notices from '../../../assets/notices';
import { useState } from 'react';
import useMobile from '../../../hooks/useMobile';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 6;

const Notice = () => {
  const { isMobile } = useMobile();
  const [pageIndex, setPageIndex] = useState(1);
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.notice_container}>
        <div className={styles.header}>
          <div className={styles.title}>Notice</div>
          <div className={styles.subtitle}>PR & IR</div>
        </div>

        <div className={styles.content_wrapper}>
          {notices
            .slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE)
            .map((_, i) => (
              <div className={styles.row}>
                <div className={styles.date}>{_.date}</div>
                <div
                  className={styles.title}
                  onClick={() => {
                    if (_.type === 'PR') {
                      window.open(_.link);
                    } else if (_.type === 'IR') {
                      navigate(`/notice/${_.id}`);
                    }
                  }}
                >
                  {_.title}
                </div>
                <div className={styles.icons}>
                  {_.attachements.length > 0 && (
                    <img src={FileIcon} alt="file" />
                  )}
                  {!isMobile && <img src={ArrowRightIcon} alt="arrow" />}
                </div>
              </div>
            ))}
        </div>
        <div className={styles.pagination}>
          <img
            src={ArrowLeftIcon}
            alt="arrow"
            onClick={() => {
              if (pageIndex > 1) {
                setPageIndex(pageIndex - 1);
              }
            }}
          />
          {Array.from({ length: Math.ceil(notices.length / PAGE_SIZE) }).map(
            (_, i) => {
              return (
                <div
                  className={`${styles.page} ${
                    i + 1 === pageIndex && styles.active
                  }`}
                  onClick={() => {
                    setPageIndex(i + 1);
                  }}
                >
                  {i + 1}
                </div>
              );
            },
          )}
          <img
            src={ArrowRightIcon}
            alt="arrow"
            onClick={() => {
              if (pageIndex < Math.ceil(notices.length / PAGE_SIZE)) {
                setPageIndex(pageIndex + 1);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Notice;
