import Header from './Header/Header';
import styles from './Main.module.scss';
import art1 from '../assets/arts/1.png';
import art2 from '../assets/arts/2.png';
import art3 from '../assets/arts/3.png';
import art4 from '../assets/arts/4.png';
import art5 from '../assets/arts/5.png';
import art6 from '../assets/arts/6.png';
import art7 from '../assets/arts/7.png';
import art8 from '../assets/arts/8.png';
import art9 from '../assets/arts/9.png';

import { useEffect, useRef, useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import useInterval from '../hooks/useInterval';

import AVideo from '../assets/videos/media_1.mp4';
import BVideo from '../assets/videos/media_2.mp4';
import CVideo from '../assets/videos/media_3.mp4';
import DVideo from '../assets/videos/media_4.mp4';
import EVideo from '../assets/videos/media_5.mp4';

import BG from '../assets/bg.png';
import { styled } from 'styled-components';

import LogoWithLine from '../assets/logo_with_line.svg';
import Members from './Members/Members';
import useOnScreen from '../hooks/useOnScreen';
import useMobile from '../hooks/useMobile';

const arts = [art9, art1, art2, art3, art4, art5, art6, art7, art8];

const Video = styled.video<{ degree: number; width?: string }>`
  transform: perspective(5000px) rotateY(${(props) => props.degree}deg);
  transform-style: preserve-3d;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;

  width: ${(props) => (props.width ? props.width : '100%')}}};
`;

const Main = () => {
  const { isMobile } = useMobile();
  const ref = useRef<HTMLDivElement>(null);
  const isMemberCardVisible = useOnScreen(ref);

  const topRef = useRef<HTMLDivElement>(null);
  const artistRef = useRef<HTMLDivElement>(null);
  const isArtistVisible = useOnScreen(artistRef);

  const ranges = [0, 1, 2];
  const [index, setIndex] = useState(0);

  const controls = [
    useAnimationControls(),
    useAnimationControls(),
    useAnimationControls(),
  ];

  const controlsMobile = useAnimationControls();

  const vidRef1 = useRef<HTMLVideoElement | null>(null);
  const vidRef2 = useRef<HTMLVideoElement | null>(null);
  const vidRef3 = useRef<HTMLVideoElement | null>(null);
  const vidRef4 = useRef<HTMLVideoElement | null>(null);
  const vidRef5 = useRef<HTMLVideoElement | null>(null);
  const vidRefs = [vidRef1, vidRef2, vidRef3, vidRef4, vidRef5];

  useInterval(() => {
    setIndex(index + 1);
  }, 5000);

  const transition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 1.5,
  };

  useEffect(() => {
    if (isMobile) {
      controlsMobile.set({ opacity: 0 });
      controlsMobile.start({
        opacity: 1,
        transition,
      });
    }
    controls.forEach((control) => {
      control.start({
        x: `${-(index + 1) * 100}vw`,
        transition,
      });
    });
  }, [index]);

  useEffect(() => {
    vidRefs.forEach((vidRef, index) => {
      setTimeout(() => {
        vidRef.current?.play();
      }, 100 * index);
    });
  }, []);

  const [ratio, setRatio] = useState(0.0);
  const [position, setPosition] = useState(0);

  const getScrollPosition = () => {
    const scrollY = window.scrollY;
    const { innerHeight } = window;
    const percentage = scrollY / innerHeight;
    setPosition(percentage);
  };

  useEffect(() => {
    const mouseMove = (e: MouseEvent) => {
      const { clientX } = e;
      const { innerWidth } = window;
      setRatio(clientX / innerWidth);
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('scroll', getScrollPosition);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('scroll', getScrollPosition);
    };
  }, []);

  return (
    <div style={{ width: '100vw' }} onScroll={getScrollPosition}>
      <Header dark={isMemberCardVisible && !isArtistVisible} />
      {!isMobile && (
        <div className={styles.wrapper} ref={topRef}>
          {ranges.map((range) => (
            <motion.div
              className={styles.img_container}
              animate={controls[range]}
              style={{
                left: `${(range + index) * 100}vw`,
              }}
            >
              <img
                // key={pointer + index}
                src={arts[(range + index) % arts.length]}
                alt="`${index} fishing`"
                className={styles.image}
                draggable={false}
              />
            </motion.div>
          ))}
        </div>
      )}
      {isMobile && (
        <div className={styles.wrapper_mobile}>
          <motion.div className={styles.img_container} animate={controlsMobile}>
            <img
              src={arts[index % arts.length]}
              alt="`${index} fishing`"
              className={styles.image}
              draggable={false}
            />
          </motion.div>
        </div>
      )}
      <div className={styles.slogan}>We Create Eternity and Infinity</div>
      <div className={styles.slider_footer}>
        {arts.map((art, i) => (
          <div
            className={styles.slider_footer_item_wrapper}
            onClick={() => {
              setIndex(index + i - (index % arts.length));
            }}
          >
            <div
              className={`${styles.slider_footer_item} ${
                i === index % arts.length ? styles.active : ''
              }`}
            />
          </div>
        ))}
      </div>
      <div className={styles.wrapper_philosophy}>
        <div className={styles.philosophy_background}>
          {!isMobile && (
            <img
              src={BG}
              alt="bg"
              style={{
                transform: `translateY(-${position * 300 + 500}px)`,
                // opacity: 0.35,
                filter: 'brightness(0.8)',
                width: '100vw',
              }}
            />
          )}
        </div>
        <div className={styles.philosophy_title}>
          Multi Usable IP is Eternal & Infinite
        </div>
        <div className={styles.philosophy_paragraph}>
          The Origin Creative Group is a gathering of experts from various
          fields including webnovel, webtoon, comics, game, drama, and film, who
          plan content that can be expanded into all genres. This planning is
          done with the producing of IP business experts, implementing these
          plans across all content areas.
        </div>
        {!isMobile && (
          <div className={styles.img_container}>
            <div className={styles.img_a}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef1}
              >
                <source src={AVideo} />
              </Video>
            </div>
            <div className={styles.img_b}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef2}
              >
                <source src={CVideo} />
              </Video>
            </div>
            <div className={styles.img_c}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef3}
              >
                <source src={DVideo} />
              </Video>
            </div>
            <div className={styles.img_d}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef4}
              >
                <source src={BVideo} />
              </Video>
            </div>
            <div className={styles.img_e}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef5}
              >
                <source src={EVideo} />
              </Video>
            </div>
          </div>
        )}
        {isMobile && (
          <div className={styles.img_container_mobile}>
            <div className={styles.img_a}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef1}
              >
                <source src={AVideo} />
              </Video>
            </div>
            <div className={styles.img_b}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef2}
              >
                <source src={CVideo} />
              </Video>
            </div>
            <div className={styles.img_c}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef3}
              >
                <source src={DVideo} />
              </Video>
            </div>
            <div className={styles.img_d}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef4}
              >
                <source src={BVideo} />
              </Video>
            </div>
            <div className={styles.img_e}>
              <Video
                degree={15 * ratio - 7.5}
                playsInline
                loop
                muted
                ref={vidRef5}
              >
                <source src={EVideo} />
              </Video>
            </div>
          </div>
        )}
        <div className={styles.philosophy_left}>
          <div className={styles.philosophy_paragraph}>
            By doing so, we aim to break down the traditional boundaries of
            content that do not fit in the era of hyper-personalization,
            creating a new dimension of integrated enjoyment. It is our mission
            to continuously build creative intellectual property that will exist
            perpetually, fulfilling our vision in this age.
          </div>
        </div>
      </div>
      <div className={styles.artists_container} ref={artistRef}>
        <div className={styles.flex}></div>
        <div className={styles.flex}>
          <div className={styles.title}>Artist</div>
          <div className={styles.content}></div>
        </div>
      </div>
      <div ref={ref}>
        <Members />
      </div>

      <div className={styles.wrapper_credit}>
        <div className={styles.footer}>
          <div className={styles.left}>
            <img src={LogoWithLine} alt="logo" />
            {/* <p>PRIVACY POLICY CONTACT</p> */}
            <p>225, 80-90, UN village-gil, Yongsan-gu, Seoul, Korea</p>
            <p>© The Origin Inc. All Rights Reserved.</p>
          </div>
          <div className={styles.right}>
            <div
              className={styles.menu}
              onClick={() => {
                topRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              The Origin
            </div>
            {!isMobile && <div className={styles.vertical_divider} />}
            <div
              className={styles.menu}
              onClick={() => {
                artistRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Artist
            </div>
            {!isMobile && <div className={styles.vertical_divider} />}
            <div
              className={styles.menu}
              onClick={() => {
                window.location.href = '/notice';
              }}
            >
              Notice
            </div>
            {!isMobile && <div className={styles.vertical_divider} />}
            <div
              className={styles.menu}
              onClick={() => {
                window.location.href = '/contact';
              }}
            >
              Contact
            </div>
            {!isMobile && <div className={styles.vertical_divider} />}
            <div
              className={styles.menu}
              onClick={() => {
                window.open('https://theorigin.team/');
              }}
            >
              Career
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
