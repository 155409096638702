/* eslint-disable jsx-a11y/label-has-associated-control */
import Header from '../../Header/Header';
import styles from './Contact.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import LinkIcon from '../../../assets/icons/link.svg';
import DownloadIcon from '../../../assets/icons/download.svg';
import PrivacyModal from '../../common/PrivacyModal';

type Inputs = {
  name: string;
  company: string;
  email: string;
  contactInformation: string;
  department: string;
  directTitle: string;
  inquiryDetails: string;
  isConsent: boolean;
};

const Contact = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const form = useRef(HTMLFormElement.prototype);

  const [submit, setSubmit] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    emailjs
      .sendForm(
        'service_5gudxr5',
        'template_x3dgdkr',
        form.current,
        'IWBxg3YF8WE2EK0Lv',
      )
      .then(
        (result) => {
          setSubmit(true);
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  const handleDownload = (url: string, fileName: string) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <PrivacyModal open={open} handleClose={handleClose} />
      <div className={styles.contact_container}>
        <div className={styles.header}>
          <div className={styles.title}>Contact</div>
          {/* <div className={styles.subtitle}>PR & IR</div> */}
        </div>
        {!submit && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.form}
            ref={form}
          >
            <input
              {...register('name', { required: true })}
              placeholder="Name"
              className={errors.name && styles.error}
            />
            <input
              {...register('company', { required: true })}
              placeholder="Company / Enterprise"
              className={errors.company && styles.error}
            />
            <input
              {...register('email', { required: true })}
              placeholder="E-mail"
              className={errors.email && styles.error}
            />
            <input
              {...register('contactInformation', { required: true })}
              placeholder="Contact Information"
              className={errors.contactInformation && styles.error}
            />
            <input
              {...register('department')}
              placeholder="Department (Optional)"
            />
            <input
              {...register('directTitle')}
              placeholder="Direct Title (Option)"
            />
            <input
              {...register('inquiryDetails')}
              placeholder="Inquiry Details (Option)"
            />
            <span className={styles.checkbox}>
              <input
                type="checkbox"
                {...register('isConsent', { required: true })}
              />
              <span>I give consent to </span>
              <span
                className={styles.link}
                onClick={() => {
                  setOpen(true);
                }}
              >
                collection and use of my personal information
                <img src={LinkIcon} alt={'link_icon'} />
              </span>
              <span> for the inquiry.</span>
            </span>

            <input type="submit" value={'Submit'} />
          </form>
        )}
        {submit && (
          <div className={styles.submitted_container}>
            <p>
              Your inquiry has been completed.
              <br /> Thank you.
            </p>
            <div
              className={styles.button}
              onClick={() => (window.location.href = '/')}
            >
              Home
            </div>
          </div>
        )}
        <div className={styles.company_introduction}>
          <div className={styles.title}>Company Introduction</div>
          <div className={styles.button_wrapper}>
            <div
              className={styles.button}
              onClick={() => {
                handleDownload(
                  'https://the-origin-file.s3.ap-northeast-2.amazonaws.com/company_introduction/%5BThe+Origin%5D+Company+Introduction+1.0+(kr).pdf',
                  '[The Origin] Company Introduction 1.0 (kr).pdf',
                );
              }}
            >
              <img src={DownloadIcon} alt={'download_icon'} />
              <span>국문</span>
            </div>
            <div
              className={styles.button}
              onClick={() => {
                handleDownload(
                  'https://the-origin-file.s3.ap-northeast-2.amazonaws.com/company_introduction/%5BThe+Origin%5D+Company+Introduction+1.0+(en).pdf',
                  '[The Origin] Company Introduction 1.0 (en).pdf',
                );
              }}
            >
              <img src={DownloadIcon} alt={'download_icon'} />
              <span>English</span>
            </div>
            <div
              className={styles.button}
              onClick={() => {
                handleDownload(
                  'https://the-origin-file.s3.ap-northeast-2.amazonaws.com/company_introduction/%5BThe+Origin%5D+Company+Introduction+1.0+(jp).pdf',
                  '[The Origin] Company Introduction 1.0 (jp).pdf',
                );
              }}
            >
              <img src={DownloadIcon} alt={'download_icon'} />
              <span>日本語</span>
            </div>
            <div
              className={styles.button}
              onClick={() => {
                handleDownload(
                  'https://the-origin-file.s3.ap-northeast-2.amazonaws.com/company_introduction/%5BThe+Origin%5D+Company+Introduction+1.0+(cn).pdf',
                  '[The Origin] Company Introduction 1.0 (cn).pdf',
                );
              }}
            >
              <img src={DownloadIcon} alt={'download_icon'} />
              <span>中文</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
