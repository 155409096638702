import 강범진 from './강범진.png';
import 강지석 from './강지석.png';
import 김미은 from './김미은.png';
import 김칸비 from './김칸비.png';
import 까를로스 from './까를로스.png';
import 레팔진프 from './레팔진프.png';
import 서에녹 from './서에녹.png';
import 성대경 from './성대경.png';
import 오인재 from './오인재.png';
import 정재식 from './정재식.png';
import 조민수 from './조민수.png';
import 최원호 from './최원호.png';
import 쿠와사시마사노리 from './쿠와사시마사노리.png';

export type Member = {
  code: string;
  name: string;
  en_name: string;
  en_bio: string[];
  image: string;
  bio: string[] | null;
  works: string[] | null;
  edu: string[] | null;
  instagram: string | null;
  homepage: string | null;
};

export const creators: Member[] = [
  {
    en_name: 'RBman. JO',
    en_bio: [
      'General Art Director of EA Games\' "The Lord of the Rings: Conquest"',
      '"Snowpiercer", "The Host", "Alienoid", etc.',
    ],
    code: 'rbman',
    name: '조민수',
    image: 조민수,
    bio: [
      '디오리진 Creative Director',
      '모팩 / 아트센터장(상무)',
      'EA Games / Art Director',
      '폴리곤비쥬얼웍스폴리곤 게임스 창업',
      '아이미디어',
    ],
    works: [
      '설국열차',
      '괴물',
      '올드보이',
      '달콤한인생',
      'Speed Racer',
      '데드스페이스',
      '반지의제왕 등',
    ],
    edu: null,
    instagram: null,
    homepage: 'https://www.rbman.com',
  },
  {
    en_name: 'Carnby. KIM',
    en_bio: [
      'Naver Webtoon with 1.2 billion cumulative views,No.1 on Global Netflix, "Sweet Home"',
      '"Bastard", "Pigpen", "Superior Day", "Flawed Almighty", "Shotgun Boy", etc.',
    ],
    code: 'carnby',
    name: '김칸비',
    image: 김칸비,
    bio: null,
    edu: null,
    works: [
      '스위트홈',
      '후레자식',
      '우월한 하루',
      '언노운 코드',
      '폐쇄인간',
      '돼지우리',
      '엽총소년',
      '교수인형',
      '멜로홀릭',
      '죽은 마법사의 도시',
    ],
    instagram: null,
    homepage: null,
  },
  {
    en_name: 'Carlos',
    en_bio: [
      'Kakao Webtoon with 7 million cumulative page views, No.1 in Viu Middle East, Southeast Asia "Taxi Driver” ',
      '"Alternate Character Life", "Solmari Video Room", etc.',
    ],
    code: 'carlos',
    name: '까를로스',
    image: 까를로스,
    bio: null,
    works: [
      '모범택시',
      '부캐인생',
      '보통아이',
      '하슬라',
      '원킬',
      '설마리비디오방',
      '사연',
      '세대전쟁',
      '고려연방고',
      'mic',
    ],
    edu: null,
    instagram: 'https://www.instagram.com/carlos_paxx/',
    homepage: null,
  },
  {
    en_name: 'Bumjin. KANG',
    en_bio: [
      'Introduced the worldview in SBS "Finding Genius”',
      'Concept Artist',
    ],
    code: 'kangbumjin',
    name: '강범진',
    image: 강범진,
    bio: ['SBS <영재발굴단> 소개', '세계관 / 컨셉 아티스트'],
    works: null,
    edu: null,
    instagram: 'https://www.instagram.com/kangbumjin_lyh/',
    homepage: null,
  },
  {
    en_name: 'Kuwasashi Masanori',
    en_bio: [
      'General Director of the early "The King of Fighters" Series 94-96',
    ],
    code: '쿠와사시마사노리',
    name: '쿠와사시 마사노리',
    image: 쿠와사시마사노리,
    bio: ['킹오브 파이터즈 기획자'],
    works: [
      'The King of Fighters ‘95',
      'Tenchu : Wrath of Heaven',
      'Tenchu : Return From Darkness',
      'The King of Fighters ‘94 Re-Boot 등',
    ],
    edu: null,
    instagram: null,
    homepage: null,
  },
  // {
  //   en_name: 'Lepaljinf',
  //   en_bio: [
  //     'Winner of the 2020 Navers Greatest Contest in the Romance Fantasy category "I\'m the Queen in This Life"',
  //   ],
  //   code: '레팔진프',
  //   name: '레팔진프',
  //   image: 레팔진프,
  //   bio: null,
  //   edu: null,
  //   works: ['언니, 이번생엔내가왕비야'],
  //   instagram: null,
  //   homepage: null,
  // },
];

export const members: Member[] = [
  {
    en_name: 'Jesse. JUNG',
    en_bio: [
      'Team Leader of Netmarble IP Business, Hyundai Motor AI Platform Planning, Unbox CCO, Comove CCO',
      '"Summoners War by Com2uS", "Guardian Tales by Kong Studios", etc.',
    ],
    code: 'jjs',
    name: '정재식',
    image: 정재식,
    bio: [
      '넷마블 / IP사업팀장',
      '현대자동차 / AI플랫폼기획',
      '언박스 / CCO',
      '커무브 / CCO',
    ],
    works: [
      'Equity/PF 투자/IP사업 투자 총괄 (400억+)',
      '가디언테일즈, 서머너즈워 세계관 등',
      '세븐나이츠 애니메이션, 좀비런 등',
    ],
    edu: ['연세대학교 경제학', '대일외국어고등학교'],
    instagram: null,
    homepage: null,
  },
  {
    en_name: 'DK. SUNG',
    en_bio: [
      'Hyundai Motor ZER01NE Co-founder, Head of Brand and Fund Management, KEPCO, Unbox CEO',
    ],
    code: '성대경',
    name: '성대경',
    image: 성대경,
    bio: [
      '현대자동차 / ZER01NE Team',
      'ZER01NE / Co-founder',
      '한국전력공사',
      '언박스 / CEO',
    ],
    edu: ['중앙대학교 법학'],
    works: [
      'ZER01NE 브랜드 기획 / 아트 프로젝트 총괄',
      'ZER01NE Fund 핵심운용인력 (805억)',
      'ZER01NE DAY Festival Director',
    ],
    instagram: null,
    homepage: null,
  },
  {
    en_name: 'Enoch. SEO',
    en_bio: [
      'Pala Business & Operations Lead, Place A CMO',
      'Representative of Sharehouse Yellow Forest, Fast Retailing Uniqlo Marketing PM',
    ],
    code: '서에녹',
    name: '서에녹',
    image: 서에녹,
    bio: [
      '팔라 / Business & Operation Team Lead',
      '플레이스에이 / CMO',
      '쉐어하우스 노란숲 대표',
      '유니클로 / 마케팅팀 Senior PM',
    ],
    edu: ['서강대학교 컴퓨터공학, 경영학'],
    works: [
      '팔라 Web3 프로젝트 사업 기획',
      'Human Body Pose Estimation AI 사업 전략',
      '플레이스에이 컴퍼니 빌딩 및 커뮤니케이션 총괄',
    ],
    instagram: null,
    homepage: null,
  },
  {
    en_name: 'Wonho. CHOI',
    en_bio: [
      'Kong Studios CIO/CFO, Zigbang Investment Strategy Lead',
      'Com2uS, Heungkuk F&B, District, Deloitte, KPMG',
    ],
    code: '최원호',
    name: '최원호',
    image: 최원호,
    bio: [
      'KONG studios / CFO & CIO',
      '직방 / 투자 팀장',
      'com2us',
      '마이다스동아인베스트먼트',
      '흥국F&B',
      '디스트릭트홀딩스',
      '딜로이트 안진회계법인',
    ],
    edu: ['위스콘신 대학교, 경제학'],
    works: ['콩스튜디오 시리즈 B 투자 유치, 유니콘 등극'],
    instagram: null,
    homepage: null,
  },
  {
    en_name: 'Jesse. KANG',
    en_bio: [
      'Head of Film Distribution Strategy at CJ ENM, Naver Webtoon KR Content Team',
      'Cheil PengTai Communication Team, Lotte Cultureworks Overseas Business Team',
    ],
    code: '강지석',
    name: '강지석',
    image: 강지석,
    bio: [
      'CJ ENM / 영화드라마 사업부 파트장',
      '네이버웹툰 / KR 콘텐츠 팀 과장',
      '제일펑타이',
      '롯데컬쳐웍스',
    ],
    edu: ['북경대학교 국제관계학과 국제정치학, 국제법학'],
    works: [
      '프랜차이즈 IP 개발 및 트랜스미디어 사업 진행',
      'IP 소싱 전략 기획 및 운영',
      '웹툰 IP 개발 (담당 작가 50명 내외)',
      '중국 현지 서비스 전략/기획/운영 및 IP 소싱',
    ],
    instagram: null,
    homepage: null,
  },
  {
    en_name: 'Mieun. KIM',
    en_bio: [
      'Head of Live Service Operations at LINE Games',
      'Game PM at LINE Plus, NCSoft R&D Center',
    ],
    code: '김미은',
    name: '김미은',
    image: 김미은,
    bio: [
      '라인게임즈 사업실 / 실장',
      '라인플러스 글로벌 게임 사업',
      '엔씨소프트 / 리니지 및 아이온 사업, 라이브 마케팅',
      'Plansahead',
    ],
    edu: ['서강대학교 생명과학'],
    works: [
      '대항해시대 오리진 글로벌 런칭',
      '언디셈버 국내 런칭, 모바일 마켓 10위 기록, 런칭 첫 달 매출 170억 (MAU 70만)',
      '대항해시대 오리진, 2022 대한민국 게임 대상 4관왕 수상',
    ],
    instagram: null,
    homepage: null,
  },
  // {

  //   code: 'YoshitakaAmano',
  //   name: '아마노 요시타카',
  //   image: 아마노요시타카,
  //   bio: ['파이널 판타지 전 시리즈 컨셉 및 로고디자인 '],
  //   edu: null,
  //   works: ['파이널 판타지 시리즈', '프론트 미션 시리즈 등'],
  //   instagram: 'https://www.instagram.com/yoshitaka_amano/',
  //   homepage: 'https://www.yoshitakaamano.com/',
  // },
  {
    en_name: 'Injae. OH',
    en_bio: [
      'Soundcat, Catsco  / Head of Management Assistant, NH Nonghyup Life / Management Support Division',
    ],
    code: '오인재',
    name: '오인재',
    image: 오인재,
    bio: ['Soundcat, catsco / 경영관리본부장', 'NH농협생명 / 경영지원본부'],
    edu: ['건국대학교 국문학'],
    works: [
      '2017 네이버 음악차트 1, 2위 동시달성',
      '2017 자라섬 재즈 페스티발',
      '2014 대학가요제 금상',
    ],
    instagram: null,
    homepage: null,
  },
];
