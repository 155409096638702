/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

const PageContext = createContext({
  page: 0,
  changePage: (changePage: number) => {},
});

interface PageProviderProps {
  children: JSX.Element;
}

const PageProvider = ({ children }: PageProviderProps) => {
  const [page, setPage] = useState(0);

  const changePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <PageContext.Provider
      value={{
        page,
        changePage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };
